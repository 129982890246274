import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import woonhavenfullImg from "../../images/projectsfull/woonhavenfull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Woonhaven</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Woonhaven"
              projectfulldescription="For this prospective EntityOne customer I designed a website after carefully studying the user experience by means of wireframing and analysing concerns they had with their current website."
              projectclient="Woonhaven"
              projectrole="Web Design"
              projectdate="2019"
              projectfullimg={<div className="project--img--inner"><img data-src={woonhavenfullImg} className="project--img lazyload" alt="Woonhaven" /></div>}
              projectnextlink="/projects/logoanimation"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;